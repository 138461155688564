.audio-container {
  //   background-color: #0e2634;
  border-color: #0000009c;
  background-color: #a55eea;
  border-radius: 7px;
  border-style: solid;
  border-width: 1px;

  height: 250px;
  width: 350px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  text-align: center;
  padding: 24px 20px 0px 24px;
  color: #707070;

  input[type="range"] {
    margin-left: -10px;
    margin-top: 5px;
    border-radius: 6px;

    -webkit-appearance: none;
    outline: none;
    background-color: #f9ce29;
    width: 90%;
    height: 6px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #f9ce29;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
}

.audio-bar {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  align-self: center;
  margin-left: -10px;
  margin-top: 3px;
  position: relative;
  border: 2px solid #f9ce29;
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio-circle {
  margin-top: 0px;
  // margin-bottom: 10px;
}
.slide-bar {
  // background: linear-gradient(to right, #82CFD0 0%, #82CFD0 50%, #F79796 50%,#F79796 100%);
  // border: solid 1px #F79796;
  // border-radius: 8px;
  // height: 6px;
  // width: 90%;
  // align-self: center;
  // margin-left: -10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // outline: none;
  // -webkit-appearance: none;
}

@media screen and (max-width: 400px) {
  .audio-container {
    width: 100%;
  }
}
