@import "../../../../colors.scss";

.buttonContainer {
  width: 109px;
  height: 102px;
  background-color: $Yellow;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8%;
  margin-bottom: 8%;
}
.powerIcon {
  width: 100%;
  height: 100%;
  background-color: $Yellow;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.powerUpCardIcon {
  width: 42px;
  height: 33px;
  object-fit: contain;
}
.powerUpCardClose {
  position: absolute;
  width: 110px;
  height: 102px;
  object-fit: contain;
}
.cardTitle {
  font-family: "Bebas Neue" !important;
}

@media only screen and (max-width: 1680px) {
  .buttonContainer {
    width: 90px;
    height: 85px;
    margin: 3%;
  }
  .powerUpCardClose {
    width: 85px;
    height: 80px;
  }
}
@media only screen and (max-width: 1440px) {
  .buttonContainer {
    width: 70px;
    height: 65px;
    margin: 3%;
  }
  .powerUpCardClose {
    width: 65px;
    height: 60px;
  }
  .powerUpCardIcon {
    width: 32px;
    height: 23px;
    object-fit: contain;
  }
  .cardTitle {
    font-size: 1.1em !important;
  }
}
@media only screen and (max-width: 1380px) {
  .buttonContainer {
    margin: 3.5%;
  }
}
@media only screen and (max-width: 1280px) {
  .buttonContainer {
    margin: 3%;
  }
}
