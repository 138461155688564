.container {
  width: 100%;
  background-color: #2bcbba;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.leftIcon {
  width: 38px;
  object-fit: contain;
  margin-right: 25px;
}
