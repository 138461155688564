/** @format */

$Firefly: #0e2634;
$Elephant: #113446;
$Pickled-Bluewood: #35515d;
$Heath: #5a1414;
$Pomegranate: #f53b39;
$Red: #f80705;
$Bittersweet: #ff5d5d;
$Crown-of-Thorns: #7b1e1d;
$Rolling-Stone: #75838b;
$Cape-Cod: #3b4246;
$ConiferLight: #b4ec51;
$Bilbao: #377b1b;
$San-Juan: #2c5167;
$Purple: #a06ef9;
$Dark-Purple: #474ab2;
$Conifer: #83db49;
$Baltic-Sea: #222027;
$white: #ffffff;
$Burnt-Sienna: #eb7062;
$Atomic-Tangerine: #ff9a6d;
$Periwinkle-Gray: #cdcce9;
$Pink-Salmon: #ff85a6;
$Wild-Sand: #f5f5f5;
$Turquoise: #2bcbba;
$Puerto-Rico: #51cabd;
$Froly: #f5809f;
$Java: #29c3b3;

//Trivia
$Yellow: #f9ce29;
$Green: #29c0b0;
$Trivia-Purple: #be7bff;
$Orange: #ffab2d;
$Parrot: #add53b;
$Dark-Green: #29f937;
$Pink: #ff9497;

//tablecolors
$table-heading: #777382;
$table-data: #222027;
$table-stripped: #e1c2ff;
$container-border: #707070bb;

$amethyst-smoke: #9a87af;
$trendy-pink: #80689a;
