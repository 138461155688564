.settingBoxContentText {
  width: 35%;
}
.settingBoxContentBox {
  display: flex;
  justify-content: flex-start;
}

.sliderContainer {
  width: 263px;
  display: flex;
  justify-content: center;
  align-items: center;

  input[type="range"] {
    border-radius: 6px;
    -webkit-appearance: none;
    outline: none;
    background-color: #e2c5c6 !important;
    height: 12px;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #f9ce29 !important;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
}
