@import "../../../css/mixin.scss";
.menu-header {
  @include BGContainer;
  background-image: url("../../../assets/BG/loginBG.svg");
}
.createCollectionContainer {
  display: flex;
  width: 100%;
  margin-top: 215px;
  padding: 0px 50px 0px 50px;
}
.createCollectionContainerLeft {
  display: flex;
  justify-content: center;
  width: 350px;
  margin-right: 20px;
}
.createCollectionContainerRight {
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
}
.createCollectionContainerRightTop {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 75px 20px 40px 40px;
  margin-bottom: 20px;
  @include BorderColor;
  @include DropShadow;
}
.createCollectionContainerRightBottom {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 20px 20px 20px 40px;
  @include BorderColor;
  @include DropShadow;
}
.lobbySummaryContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  padding: 100px 20px 20px 20px;
}
.summaryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.summaryButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.CollectionDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.collectionSlotContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 1100px;
  margin-bottom: 20px;
}
////////file input
.fullWidth {
  width: 100%;
  border: 2px solid #80689a;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
  height: 170px;
}
.inputLabel {
  color: #222027;
  text-align: left;
  font-size: 1.875em;
}
.CollectionTableContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.collectionTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonWrapper {
  margin-bottom: 10px;
}
.tableContainer {
  width: 100%;
  margin-bottom: 15px;
  background-color: yellow !important;
}
.tableItemHeader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.widthControl {
  // width: 60%;
  width: 720px;
}
.errorMessage {
  color: red;
  margin-top: 10px;
}
.categoryIcon {
  width: 40px !important;
  height: 40px !important;
  object-fit: contain !important;
  margin-right: 20px !important;
}
@media only screen and (min-width: 1940px) {
  .collectionSlotContainer {
    width: 75%;
  }
}
@media only screen and (max-width: 1700px) {
  .collectionSlotContainer {
    width: 100%;
  }
  .createCollectionContainer {
    padding: 0 20px 0 30px;
  }
  .widthControl {
    width: 70%;
  }
}

@media only screen and (max-width: 1540px) {
  .widthControl {
    width: 65%;
  }
}
// @media only screen and (max-width: 1380px) {
//   .menu-header{
//     font-size: 14px;
//   }
// }
@media only screen and (max-width: 1279px) {
  .questionSetSlotContainer {
    width: 100%;
  }
  .collectionSlotContainer {
    width: 100%;
  }
  .createCollectionContainer {
    flex-direction: column;
  }
  .createCollectionContainerLeft {
    width: 100%;
    margin-bottom: 30px;
  }
  .createCollectionContainerRight {
    width: 100%;
  }
  .createCollectionContainerRightTop {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 720px) {
  .header {
    font-size: 2.1vw !important;
  }
}
