@import "../../../../colors.scss";

.powerUpsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.powerUpsButtonContainer {
  background-color: $white;
  padding: 5% 0px 0px 8% !important;
  width: 79%;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 1940px) {
  .powerUpsButtonContainer {
    width: 300px;
  }
}

@media only screen and (max-width: 1680px) {
  .powerUpsButtonWrapper {
    transform: scale(0.8);
    margin-bottom: -10px;
    margin-right: -36px;
  }
  .powerUpsButtonContainer {
    width: 73%;
    width: 240px;
    padding: 0px !important;
    padding-left: 5% !important;
  }
}
@media only screen and (max-width: 1440px) {
  .powerUpsButtonWrapper {
    transform: scale(0.6);
    margin-bottom: -18px;
    margin-right: -72px;
  }
  .powerUpsButtonContainer {
    width: 63%;
    padding: 0px !important;
    padding-left: 3% !important;
  }
}
@media only screen and (max-width: 1380px) {
  .powerUpsButtonContainer {
    width: 66%;
  }
}
@media only screen and (max-width: 1300px) {
  .powerUpsButtonContainer {
    width: 69%;
  }
}
@media only screen and (max-width: 1279px) {
  .powerUpsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .powerUpsButtonWrapper {
    margin-top: 10px;
    transform: scale(1);
    margin-bottom: 0px;
    margin-right: 0px;
    margin-right: 10px;
  }
  .powerUpsButtonContainer {
    width: 373px;
    padding: 0px !important;
    padding-left: 0px !important;
  }
}
