@import "../../../css/mixin.scss";
.menu-header {
  @include BGContainer;
  background-image: url("../../../assets/BG/loginBG.svg");
}
.addQuestionContainer {
  display: flex;
  width: 100%;
  margin-top: 215px;
  padding: 0px 50px 0px 50px;
}
.addQuestionContainerLeft {
  display: flex;
  width: 350px;
  margin-right: 20px;
}
.addQuestionContainerRight {
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
}
.addQuestionContainerRightTop {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 75px 20px 20px 74px;
  margin-bottom: 20px;
  @include BorderColor;
  @include DropShadow;
}
.addQuestionContainerRightBottom {
  @extend .addQuestionContainerRightTop;
  padding: 20px 20px 20px 74px;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.summaryButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.addQuestionDetailsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}
.collectionSlotContainer {
  display: flex;
  width: 1120px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0 0 20px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.widthController {
  width: 720px;
}
.answerTypeSlotContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputSlotMargins {
  margin-right: 84px;
}
.inputAnswerTopSlot {
  display: flex;
  width: 100%;
}
.addAnswerSlotContainer {
  width: 530px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.variationTagLine {
  @extend .requiredTitle;
  text-align: right;
}
.addAnswerSlotContainer1 {
  @extend .addAnswerSlotContainer;
}

.addAnswerSlotContainerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.requiredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.requiredTitle {
  font-size: 0.75em;
  font-family: "Montserrat";
  font-style: italic;
  color: #000000;
  font-weight: 300;
  margin-right: 10px;
}
.requiredIcon {
  width: 12px;
  object-fit: contain;
}
.addAnswerSlotContainerMiddle {
  display: flex;
  width: 100%;
}
.addAnswerSlotContainerBottom {
  display: flex;
  justify-content: flex-end;
}
.checkboxTitle {
  font-size: 1.0625em !important;
  color: #000000 !important;
  margin-top: 10px;
  font-family: "Montserrat" !important;
  font-weight: 500;
}

.answersList {
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  width: 100%;
}

.errorCheckboxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5px;
}
.selectAnswerWrapper {
  width: 100%;
  padding-left: 20px;
}
.answerTopSlot {
  display: flex;
  flex-direction: row;
}
.collectionDropDown {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 1940px) {
  .addAnswerSlotContainer {
    width: 38%;
  }
  .collectionSlotContainer {
    width: 80%;
  }
}
@media only screen and (max-width: 1725px) {
  .collectionSlotContainer {
    width: 100%;
  }
  .addQuestionContainer {
    padding: 0 20px 0 30px;
  }
  .widthController {
    width: 70%;
  }
  .addAnswerSlotContainer {
    width: 47%;
  }
}
@media only screen and (max-width: 1380px) {
  // .menu-header{
  //   font-size: 14px;
  // }
  .errorCheckboxContainer {
    font-size: 0.85em;
  }
}
@media only screen and (max-width: 1279px) {
  .addQuestionContainer {
    flex-direction: column;
  }
  .addQuestionContainerLeft {
    width: 100%;
    margin-bottom: 30px;
  }
  .addQuestionContainerRight {
    width: 100%;
  }
  .collectionSlotContainer {
    width: 85%;
  }
  .errorCheckboxContainer {
    font-size: 1em;
  }
}
@media only screen and (max-width: 980px) {
  .collectionSlotContainer {
    width: 95%;
  }
  .widthController {
    width: 500px;
  }
  .addQuestionContainerRightTop {
    padding: 75px 20px 20px 20px;
  }
  .addQuestionContainerRightBottom {
    padding: 20px 20px 20px 20px;
  }
}

@media only screen and (max-width: 550px) {
  .header {
    font-size: 2.7vw !important;
  }
}
