.container {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .container:active {
//     transform: scale(0.98);
//   }
.button {
  border-style: none;
  font-size: 1em;
  color: black;
}

.button:focus {
  outline: none;
}

.button:disabled {
  color: #788b96;
}

// @media screen and (max-width: 500px) {
//   .button {
//     width: 214px !important;
//     height: 55px !important;
//   }
// }

// @media screen and (max-width: 300px) {
//   .button {
//     width: 220px !important;
//     height: 50px !important;
//   }
// }
