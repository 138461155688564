/** @format */
@import "../../colors.scss";

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 0px 20px 0px 30px;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 16px;
}

.leftContainer {
  display: flex;
  align-items: center;
  width: 88%;
  justify-content: left;
}
.option {
  margin-right: 12px !important;
}
.title {
  color: $Baltic-Sea !important;
}
.tick {
  transform: scale(0.8);
  left: -5px !important;
  top: 2px !important;
}
.sabotage {
  transform: rotate(180deg) !important;
  -moz-transform:  rotate(180deg) !important;
  -webkit-transform:  rotate(180deg) !important;
  -o-transform:  rotate(180deg) !important;
  -ms-transform:  rotate(180deg) !important;
  transform:  rotate(180deg) !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.checkbox {
  height: 66px !important;
  width: 66px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: 7px solid $Yellow !important;
}
@media only screen and (max-width: 1600px) {
  .container {
    font-size: 7.5px;
    border-radius: 20px;
    height: 90%;
  }
  .checkbox {
    height: 36px !important;
    width: 36px !important;
    border-width: 4px !important;
  }
  .tick {
    transform: scale(0.4);
    left: -15px !important;
    top: -10px !important;
  }
  .title {
    max-height: 40px !important;
  }
  .leftContainer {
    align-items: baseline;
  }
}
@media only screen and (max-width: 1380px) {
  .container {
    font-size: 7px;
    border-radius: 16px;
  }
  .checkbox {
    height: 30px !important;
    width: 30px !important;
    border-width: 4px;
  }
  .tick {
    transform: scale(0.3);
    left: -19px !important;
    top: -13px !important;
  }
}
