.input-field {
  background-color: rgba($color: #3a4e5a, $alpha: 0);
  border-radius: 10px;

  border: 2px solid #80689a;
  font-size: 1em;
  font-weight: 400;
  width: 70%;
  min-height: 60px;
  padding-left: 20px;
  color: white;
  letter-spacing: 1px;
}
.input-field:focus {
  outline: none;
}
.input-field::placeholder {
  color: #222027;
}
