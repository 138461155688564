/** @format */

@import "../../css/mixin.scss";

.menuHeaderV2 {
  @include BGContainer;
  background-image: url("../../assets/BG/loginBG.svg");
  background-color: $Froly;
  background-blend-mode: normal;
  height: 100vh;
  color: unset;
}
.gameBodyV2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  // overflow: hidden;
}
.headerSectionV2 {
  height: 20%;
  overflow: hidden;
  // background-color: #f9ce29;
}
.headerContainerV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 80px 5px 80px;
  background-color: #f9ce29;
  color: black;
  font-family: "Neon Light" !important;
  transform: rotate(-1.2deg);
  margin-top: 5%;
  width: 100%;
  z-index: 100;
}
.headerTextV2 {
  padding-bottom: 0px !important;
}
.GameQuestionSectionV2 {
  height: calc(80% - 360px);
  width: 100%;
  display: flex;
  padding: 0px 0px 0px 10px;
  align-items: center;
}
.questionV2 {
  width: 55%;
  max-height: 84%;
  height: 84%;
  display: flex;
  // align-items: center;
  margin-top: 5%;
  // overflow: auto;
  overflow: hidden;
  font-weight: 800;
  color: white !important;
}
.imageContainerV2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25% !important;
  height: 380px !important;
  margin-left: 20px;
}
.imagePlaceholderV2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25% !important;
  height: 380px !important;
  margin-left: 20px;
}
.questionImageV2 {
  max-width: 99% !important;
  max-height: 99% !important;
  height: fit-content !important;
  width: fit-content !important;
  height: -moz-fit-content !important;
  width: -moz-fit-content !important;
  object-fit: fill !important;
  border: 10px solid $white !important;
}
.powerUpAreaV2 {
  width: 20% !important;
  // background-color: #222027;
  // min-width: 15% !important;
}
.AnswersSectionV2 {
  height: 354px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.bodyContainerBottomV2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.barContainerV2 {
  width: 99%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.countDownContainerV2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.countDownV2 {
  font-size: 1.87em !important;
  display: flex !important;
  // width: 100% !important;
  margin-right: 10px !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  color: $white !important;
  letter-spacing: 6px !important;
  font-family: "Bebas Neue" !important;
}
.answersFooterV2 {
  display: flex;
  justify-content: space-between;
  background-color: #222027;
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 10px;
  width: 100%;
}

.inActiveSubmit {
  width: 243px;
  height: 243px;
}
.submitButtonContainer {
  width: 243px;
  height: 243px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  //outer shadow
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.17);
  //inner shadow
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.17) inset;
  -webkit-box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.17) inset;
  -moz-box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.17) inset;
}
.submitButtonSubContainer {
  @extend .submitButtonContainer;
  width: 192px;
  height: 192px;
  background-color: #e80f0f;
  //inner shadow
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16) inset;
  -webkit-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16) inset;
  -moz-box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16) inset;
  //outer shadow
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.16);
  p {
    font-size: 30px;
    font-family: "SegoeUI";
    color: white;
    font-size: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
  }
}

.selectAnswerContainer {
  display: flex;
  width: 100%;
  padding: 0px 20px 0px 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}
.answerWrapper {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  // cursor: pointer;
}

.inputAnswerTitle {
  color: $white;
  font-size: 2.875em !important;
  padding: 0px 0px 0px 70px;
  font-weight: 800 !important;
}
.inActiveAdminAnswerContainer {
  display: flex;
  padding: 0px 50px 0px 50px;
  width: 100%;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 234px;
  overflow: hidden;
}
.inActiveAdminAnswer {
  color: $white;
  font-size: 2.875em !important;
  font-weight: 800 !important;
  width: 32% !important;
  min-height: 30%;
  margin-right: 1%;
  margin-bottom: 0.5%;
}

.inputAnswerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0px 18px;
}
.InputField {
  font-size: 3.75em;
  color: $Baltic-Sea;
  width: 100%;
  background-color: $white;
  border: none;
  font-family: "SegoeUI" !important;
  padding: 37px 37px 37px 70px;
  border-radius: 30px;
  outline: none;
  font-weight: 800 !important;
  &::placeholder {
    color: #b5b3c1 !important;
  }
}
.pauseContainer {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.pauseTitle {
  font-size: 4em !important;
  font-family: "Bebas Neue" !important;
}
.pauseImage {
  margin-top: 40px;
  width: 500px;
  height: 300px;
  object-fit: contain;
}
.pauseDescription {
  margin-top: 40px;
  font-size: 2.3em !important;
  font-weight: 500;
}
// .sabotage {
//   transform: scale(-1, 1) !important;
//   -moz-transform: scale(-1, 1) !important;
//   -webkit-transform: scale(-1, 1) !important;
//   -o-transform: scale(-1, 1) !important;
//   -ms-transform: scale(-1, 1) !important;
//   transform: scale(-1, 1) !important;
// }
.sabotage{
  transform: rotate(180deg) !important;
  -moz-transform:  rotate(180deg) !important;
  -webkit-transform:  rotate(180deg) !important;
  -o-transform:  rotate(180deg) !important;
  -ms-transform:  rotate(180deg) !important;
  transform:  rotate(180deg) !important;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
@media only screen and (min-width: 1940px) {
  .questionV2 {
    margin-top: 12%;
  }
}
@media only screen and (max-width: 1750px) {
  .inActiveAdminAnswer {
    font-size: 2.5em !important;
  }
}

@media only screen and (max-width: 1600px) {
  .AnswersSectionV2 {
    height: 255px;
  }
  .GameQuestionSectionV2 {
    height: calc(80% - 255px);
  }
  .menuHeaderV2 {
    font-size: 14px;
  }
  .questionV2 {
    line-height: 40px !important;
  }
  .imageContainerV2 {
    height: 330px !important;
  }
  .imagePlaceholderV2 {
    height: 330px !important;
  }
  .InputField {
    padding: 0px 37px 0px 70px;
  }
  .inActiveSubmit {
    width: 130px;
    height: 130px;
  }
  .inActiveAdminAnswerContainer {
    height: 140px;
  }
  .inActiveAdminAnswer {
    font-size: 2em !important;
  }
  .submitButtonContainer {
    width: 130px;
    height: 130px;
  }
  .inputAnswerTitle {
    padding-bottom: 10px;
  }
  .submitButtonSubContainer {
    width: 100px;
    height: 100px;
    p {
      font-size: 16px;
    }
  }

  .InputField {
    font-size: 2.75em;
    padding: 12px 12px 12px 50px;
    border-radius: 20px;
  }
  .inputAnswerTitle {
    font-size: 2em !important;
    padding: 0px 0px 5px 50px;
  }
}

@media only screen and (max-width: 1380px) {
  .pauseContainer {
    margin-top: 20px;
  }
  .pauseImage {
    margin-top: 20px;
  }
  .pauseDescription {
    margin-top: 20px;
  }

  .questionImageV2 {
    border: 5px solid $white !important;
  }
  .menuHeaderV2 {
    font-size: 11px !important;
  }
  .questionV2 {
    line-height: 35px !important;
  }
  .AnswersSectionV2 {
    height: 245px;
  }
  .GameQuestionSectionV2 {
    height: calc(80% - 245px);
  }
  .imageContainerV2 {
    height: 270px !important;
  }
  .imagePlaceholderV2 {
    height: 270px !important;
  }
  .InputField {
    padding: 15px 37px 15px 70px;
  }

  .inActiveSubmit {
    margin-top: 1.2%;
    width: 100px;
    height: 100px;
  }
  .inActiveAdminAnswerContainer {
    height: 140px;
  }

  .submitButtonContainer {
    margin-top: 1.2%;
    width: 120px;
    height: 120px;
  }
  .submitButtonSubContainer {
    width: 100px;
    height: 100px;
    p {
      font-size: 18px;
    }
  }

  .InputField {
    font-size: 2.75em;
    padding: 10px 8px 10px 50px;
    border-radius: 16px;
  }
  .inputAnswerTitle {
    font-size: 2em !important;
    padding: 0px 0px 2px 50px;
  }
}

@media only screen and (max-width: 1279px) {
  .menuHeaderV2 {
    height: 100%;
    min-height: 100vh;
  }
  .headerSectionV2 {
    height: 170px;
  }
  .headerContainerV2 {
    margin-top: 100px;
  }
  .GameQuestionSectionV2 {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 0px 10px;
    align-items: center;
  }
  .questionV2 {
    width: 100%;
    max-height: unset;
    overflow: unset;
  }
  .menuHeaderV2 {
    min-height: 100vh;
  }
  .AnswersSectionV2 {
    height: auto;
    // min-height: 100vh;
    width: 100%;
    position: static !important;
    bottom: 0px;
  }
  .answersFooterV2 {
    align-items: center;
  }
  .imageContainerV2 {
    width: 600px !important;
    height: 400px !important;
    justify-content: center !important;
    margin-left: 0px;
    margin-top: 1%;
  }
  .imagePlaceholderV2 {
    width: 100% !important;
    height: 10px !important;
    justify-content: center !important;
    margin-left: 0px;
    margin-top: 1%;
  }
  .inActiveAdminAnswer {
    width: 48% !important;
    margin-right: 1%;
    margin-bottom: 0.5%;
  }
  .inActiveAdminAnswerContainer {
    overflow: unset;
    height: 100%;
  }

  .answerWrapper {
    width: 95%;
    margin-bottom: 20px;
  }

  .inActiveSubmit {
    width: 150px;
    height: 150px;
  }
  .submitButtonContainer {
    width: 150px;
    height: 150px;
  }
  .submitButtonSubContainer {
    width: 120px;
    height: 120px;
    p {
      font-size: 20px;
    }
  }
}
