@import "../colors.scss";
@mixin BGContainer {
  // background-image: linear-gradient(
  //     rgba($color: #a55eea, $alpha: 0.5),
  //     rgba($color: #a55eea, $alpha: 0.5)
  //   ),
  //   url("../assets/BG/MainMenu.jpg");
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  // background-size: contain;
  background-repeat: repeat;
  // background-color: $white;
  background-color: $Wild-Sand;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-family: "Bebas Neue Pro";
  font-size: 16px;
  font-weight: 400;
}
@mixin ScrollBarMixin {
  &::-webkit-scrollbar {
    width: 17px !important;
    background-color: "#000" !important;
    border-radius: 29px !important;
  }
  &::-webkit-scrollbar-track {
    background: #8fded6 !important;
    border-radius: 10px !important;
    border-style: none !important;
    box-shadow: inset 0 0 5px #8fded6 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #29c0b0 !important;
    border-radius: 10px !important;
  }
}
@mixin BorderColor {
  border: 1px solid $container-border;
}
@mixin DropShadow {
  -moz-box-shadow: 0px 20px 18px 0px rgba(0, 0, 0, 0.164);
  -webkit-box-shadow: 0px 20px 18px 0px rgba(0, 0, 0, 0.164);
  box-shadow: 0px 20px 18px 0px rgba(0, 0, 0, 0.164);
}
