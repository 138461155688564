@import "../../colors.scss";

.navbar.custom {
  position: absolute;
  background-color: #ffffff00 !important;
  width: 100%;
  font-size: 16px;
  z-index: 3;
}

.navbar.custom .custom-margin {
  // margin-left: 18%;
  margin-left: 7%;
}

.navbar.custom #navbarCollapse {
  // margin-right: 18%;
  margin-right: 2%;
}
.setting-button {
  width: 115px;
  height: 34px;
}
.pause-button {
  width: 85px;
  height: 34px;
}
.navbar.custom button.btn.btn-light.nav-item-custom-btn {
  background-color: $Elephant;
  border-style: solid;
  border-color: $Pickled-Bluewood;
  border-width: 1px;
  border-radius: 29px;
  color: black;
  padding: 5px 20px;
  text-align: center;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 500;
}

button.btn.btn-light.nav-item-custom-btn:focus {
  outline: none;
  box-shadow: none;
}

.connection-button {
  width: 150px;
  height: 34px;
}

.connection-button:focus {
  outline: none;
  box-shadow: none;
}
.logout-button {
  width: 115px;
  height: 34px;
}

.logout-button:focus {
  outline: none;
  box-shadow: none;
}

.nav-item-custom-btn-img {
  height: 15px;
  margin: 2px 0px;
}
.connected-users {
  font-size: 23px !important;
}
.icon {
  width: 25px !important;
  height: 20px !important;
  object-fit: contain !important;
}
.navbar-toggler-icon {
  background-image: url("../../assets/icons/menu.svg") !important;
}

.audio-controller {
  width: 175px;
  height: 34px;
  background-color: #a55eea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px;

  input[type="range"] {
    border-radius: 6px;
    -webkit-appearance: none;
    outline: none;
    background-color: #222027 !important;
    height: 5.5px;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: #222027;
    width: 16px;
    height: 16px;
    border-radius: 20px;
  }
}
.play-icon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}
.slider-container {
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes fadein {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.toggleIn {
  animation: fadein 1s;
  opacity: 1;
}

@media screen and (max-width: 400px) {
  .navbar.custom .custom-margin {
    margin-left: 5px;
  }
}

@media screen and (max-width: 1500px) {
  .navbar.custom .custom-margin {
    margin-left: 10%;
  }

  .navbar.custom #navbarCollapse {
    margin-right: 10%;
  }
}

@media screen and (max-width: 1200px) {
  .navbar.custom button.btn.btn-light.nav-item-custom-btn {
    font-size: 0.75em;
  }
  .navbar.custom .custom-margin {
    margin-left: 5px;
  }

  .navbar.custom #navbarCollapse {
    margin-right: 0px;
  }
}
