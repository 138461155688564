/** @format */

@font-face {
  font-family: "Harlow Regular";
  src: local("Harlow Regular"),
    url("./fonts/harlow/Harlow-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Bebas Neue Pro";
  src: local("Bebas Neue Pro"),
    url("./fonts/bebasneue/Bebas Neue Pro Regular.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Bebas Neue Pro";
  src: local("Bebas Neue Pro Bold"),
    url("./fonts/bebasneue/BebasNeueProBold.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Bebas Neue Book";
  src: local("Bebas Neue Book"),
    url("./fonts/bebasneue/Bebas Neue Pro Book.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Neon Light";
  src: local("NeonLight"),
    url("./fonts/neon/NeonLight-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUI"), url("./fonts/segoeui/SEGOEUI.TTF") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUIBold"),
    url("./fonts/segoeui/SegoeUIBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUIBlack"),
    url("./fonts/segoeui/SegoeUIBlack.ttf") format("truetype");
  font-weight: 800;
}

body {
  margin: 0;
  font-family: "Bebas Neue Pro", "Bebas Neue Book", "Neon Light", "SegoeUI",
    "Harlow Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
}

code {
  font-family: "Neon Light", "Bebas Neue Book", "Bebas Neue Pro", "SegoeUI",
    "Harlow Regular", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 17px !important;
  background-color: "#000" !important;
  border-radius: 29px !important;
}
::-webkit-scrollbar-track {
  background: #8fded6 !important;
  border-radius: 6px !important;
  border-style: none !important;
  box-shadow: inset 0 0 5px #8fded6 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff !important;
  border-radius: 10px !important;
}
