.container {
  background-color: rgba($color: #a55eea, $alpha: 0.98);
  border-color: rgba($color: #f2f2f2, $alpha: 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 20px;
  height: 330px;
  padding-bottom: 40px;
  height: max-content;
  width: 737px;
  color: white;
  font-size: 16px;
  z-index: 500;
}

.button-container {
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 35%;
  margin-top: 5%;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 780px;
    font-size: 16px;
  }
}
@media screen and (max-width: 800px) {
  .container {
    width: 550px;
    font-size: 12px;
  }

  .button-container > button {
    width: 220px !important;
    height: 40px !important;
    font-size: 1em !important;
  }
}
@media screen and (max-width: 500px) {
  .container {
    width: 300px;
    font-size: 0.65em;
  }
  .button-container > button {
    font-size: 1.2em !important;
  }
}
